import API from '@/app/api/internalAPIs';
import { getCurrentLocaleForDateUtils } from '@/app/utils/helper';
import Service from '@/app/utils/service';
import translate from '@/app/utils/translate';

import {
  EmployeeBasicData,
  FormioI18n,
  FormSubmission,
  GridTemplateListItem,
} from './types';
import { FormioComponent, FormioFormBuilderConfig } from './components/CustomBuilder/types';
import { FormioCustomNavButtons, formioNavButtons } from './EmploymentFormDetails/NavButtonSettings';

export const normalizeString = (str: string) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const getAllComponentsFromTemplate = (
  formConfigComponents: any[],
): any[] => {
  if (!formConfigComponents || !formConfigComponents.length) {
    return [];
  }

  return formConfigComponents.reduce((acc: any, comp: any) => {
    if (!comp.components && !comp.columns && !comp.properties) {
      return acc;
    }

    if (comp.components) {
      return acc.concat([comp], getAllComponentsFromTemplate(comp.components));
    }

    if (comp.columns) {
      return acc.concat([comp], getAllComponentsFromTemplate(comp.columns));
    }

    return acc.concat([comp]);
  }, []);
};

export const getFormioFriendlyCurrentLanguage = getCurrentLocaleForDateUtils;
export const overrideDefaultTranslations = (
  formI18n: FormioI18n,
  currentLanguage: string,
): FormioI18n => {
  return {
    ...formI18n,
    [currentLanguage]: Object.keys(formI18n[currentLanguage]).reduce(
      (acc, enKey) => {
        return {
          ...acc,
          [enKey]: translate.t(enKey),
        };
      },
      {},
    ),
  };
};

export const fetchAllFormTemplates = () =>
  new Promise<GridTemplateListItem[]>((resolve, reject) => {
    Service.get(
      API.forms.getAllForms(),
      (resp: GridTemplateListItem[]) => {
        resolve(resp);
      },
      (err: Error) => {
        reject(err);
      },
    );
  });

const fetchPendingEmployeeSummary = (empId: string | number) =>
  new Promise<EmployeeBasicData>((resolve, reject) => {
    Service.get(
      API.employee.basic(empId),
      (empData: EmployeeBasicData) => {
        resolve(empData);
      },
      (err: Error) => {
        reject(err);
      },
    );
  });

export const fetchPendingEmployees = () =>
  new Promise<EmployeeBasicData[]>((resolve, reject) => {
    Service.get(
      API.employee.pendingList(),
      (empList: EmployeeBasicData[]) => {
        resolve(empList);
      },
      (err: Error) => {
        reject(err);
      },
    );
  });

export const fetchFormSubmissions = (formId: string) =>
  new Promise<FormSubmission[]>((resolve, reject) => {
    Service.get(
      API.forms.formSubmissions(formId),
      (submissions: FormSubmission[]) => {
        resolve(submissions);
      },
      (err: Error) => {
        reject(err);
      },
    );
  });

export const fetchProvisionalEmployeeSubmissionData = (
  pendingEmpId?: string | number,
) =>
  new Promise<FormSubmission | undefined>((resolve, reject) => {
    if (!pendingEmpId) {
      return resolve(undefined);
    }

    fetchPendingEmployeeSummary(pendingEmpId).then(empData => {
      if (empData.fEmpStatus !== 'PENDING') {
        return resolve(undefined);
      }

      Service.get(
        API.forms.formProvisionalEmpSubmission(empData.fEmpPersonNumber),
        (subResp: FormSubmission) => {
          subResp.data.ssn = empData.fEmpSocSecNum;
          return resolve(subResp);
        },
        (err: Error) => {
          return reject(err);
        },
      );
    });
  });

export const fetchNewEmployeeId = (ssn: string) =>
  new Promise((resolve, reject) => {
    Service.get(
      API.employee.ssn(ssn),
      (empId: string) => {
        resolve(empId);
      },
      (err: Error) => {
        reject(err);
      },
    );
  });

export const fetchDefaultFormConfig = () =>
  new Promise((resolve, reject) => {
    Service.get(
      API.forms.formDefault('employment'),
      (resp: any) => {
        resolve(resp);
      },
      (err: Error) => {
        reject(err);
      },
    );
  });

export const removeNavButtonSettings = (body: FormioFormBuilderConfig, formId: string) => {
  if (!formId) {
    return;
  }
  body.components.forEach(comp => {
    if (!comp.components.length) {
      return;
    }
    const lastComponetIndex = comp.components.length - 1;
    if (comp.components[lastComponetIndex].key.startsWith('navColumnsPage')) {
      comp.components.pop();
    }
  });
};

export const addCustomNavButtonSettings = (body: FormioFormBuilderConfig) => {
  const formNumberOfPages = body.components.length;
  const updatedComponentSettings = body.components.map((comp, idx) => {
    const returnNavUpdatedComponent = (navButtons: FormioCustomNavButtons) => {
      return {
        ...comp,
        buttonSettings: {
          previous: false,
          cancel: false,
          next: false,
          submit: false,
        },
        components: [
          ...comp.components,
          navButtons,
        ],
      };
    };

    // NB: navButons are config settings used to create custom navigation buttons in formio
    if (formNumberOfPages === 1 && idx === 0) {
      const customNavButtons = formioNavButtons('1', true, false, false, true);
      return returnNavUpdatedComponent(customNavButtons);
    } else if (formNumberOfPages > 1 && idx === 0) {
      const customNavButtons = formioNavButtons('1', true, false, true, false);
      return returnNavUpdatedComponent(customNavButtons);
    } else if (formNumberOfPages > 1 && (idx + 1) < formNumberOfPages) {
      const customNavButtons = formioNavButtons(`${idx + 1}`, true, true, true, false);
      return returnNavUpdatedComponent(customNavButtons);
    } else {
      const customNavButtons = formioNavButtons(`${idx + 1}`, true, true, false, true);
      return returnNavUpdatedComponent(customNavButtons);
    }
  });

  return { ...body, components: updatedComponentSettings };
};

export const removeCustomDataPage = (config: FormioFormBuilderConfig) => {
  const index = config.components.findIndex((comp) => comp.key === 'customData');
  if (index > -1) {
    config.components.splice(index, 1);
  }
};

export const addCustomDataPage = (config: FormioFormBuilderConfig, components: FormioComponent[]) => {
  const customDataPageExists = config.components.find((comp) => comp.key === 'customData');
  const length = config.components.length;
  if (customDataPageExists && customDataPageExists.components.length) {
    return;
  }
  
  const customDataPage = {
    title: 'Custom data',
    breadcrumbClickable: true,
    navigateOnEnter: false,
    saveOnEnter: false,
    scrollToTop: false,
    type: 'panel',
    label: 'Custom data',
    key: 'customData',
    components,
    input: false,
    tableView: false,
  } as unknown as FormioComponent;

  config.components.splice(length - 1, 0, customDataPage);
};
