import { useEffect, useState } from 'react';
import { FormioComponent, FormioFormBuilderConfig } from '../pages/EmploymentForms/components/CustomBuilder/types';
import { addCustomDataPage, addCustomNavButtonSettings, removeNavButtonSettings } from '../pages/EmploymentForms/common';
import Service from '@/app/utils/service';
import API from '@/app/api/internalAPIs';
import { Enum } from '@/app/redux/enums';
import { getCurrentLanguage } from '../utils/helper';

const GLOBAL: any = window;

export const useFormsCustomConfig = (formId: string, isBuilder = false) => {
  const [personnelGroupNames, setPersonnelGroupNames] = useState<Record<string, string>>();
  const [customDataComponents, setCustomDataComponents] = useState<FormioComponent[]>([]);
  
  const formioLang = getCurrentLanguage();

  useEffect(() => {
    Service.get(
      API.enum.groupCodes('PERSONNEL_GROUP_NAME', formioLang),
      (apiResp: Enum[]) => {
        const names = apiResp.reduce((acc, obj) => {
          return { ...acc, [obj.code]: obj.name };
        }, {});
        setPersonnelGroupNames(names);
      },
      (err: Error) => {
        console.error(err);
      },
    );
  }, []);

  useEffect(() => {
    if (formId) {
      Service.get(
        API.enum.listValuesByGroup('CUSTOM_ENTITY_EMPLOYEE', formioLang),
        (resp: Enum[]) => {
          Service.get(
            API.forms.getCustomPageFormsComponents(),
            (apiResp: FormioComponent[]) => {
              setCustomDataComponents(resp.map((item) => apiResp[item.code].schema));
            },
            (err: Error) => {
              console.log(err);
            },
          );
        },
        (error: Error) => {
          console.error(error);
        }
      );
    }
  }, [formId]);

  const applyCustomConfig = (formConfig: FormioFormBuilderConfig): FormioFormBuilderConfig => {
    try {
      const pageIndex = formConfig.components.findIndex(comp => comp.key === 'page4');
      const page = formConfig.components[pageIndex];

      // implements seven days vacations rule
      if (!GLOBAL.top.bnEnable7DaysVacationRule) {
        const componentIndex = page.components.findIndex((comp: FormioComponent) => comp.key === 'vacationBalanceCalculation');
        const component = page.components[componentIndex];
        formConfig.components[pageIndex].components[componentIndex].values = component.values.slice(0, 2);
      }

      // implements personnel group names dynamic translation
      if (personnelGroupNames) {
        const personnelGroupsIndex = page.components.findIndex((comp: FormioComponent) => comp.key === 'personnelGroups');
        const personnelGroupsFieldset = page.components[personnelGroupsIndex];
        formConfig.components[pageIndex].components[personnelGroupsIndex] = {
          ...personnelGroupsFieldset,
          components: personnelGroupsFieldset.components.map((comp, idx) => {
            return {
              ...comp,
              label: personnelGroupNames[`PERSONNEL_GROUP_${idx + 1}`],
              placeholder: personnelGroupNames[`PERSONNEL_GROUP_${idx + 1}`],
            };
          }),
        };
      }

      removeNavButtonSettings(formConfig, formConfig._id);
      addCustomDataPage(formConfig, customDataComponents);
  
      if (!isBuilder) {
        return addCustomNavButtonSettings(formConfig) as FormioFormBuilderConfig;
      }

      return {};
    } catch (error) {
      console.log(error);
      return formConfig;
    }
  };

  return { applyCustomConfig, customDataComponents };
};